import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Наши услуги | Откройте для себя наши услуги в Crispqix
			</title>
			<meta name={"description"} content={"Где экспертиза встречается с сочувствием"} />
			<meta property={"og:title"} content={"Наши услуги | Откройте для себя наши услуги в Crispqix"} />
			<meta property={"og:description"} content={"Где экспертиза встречается с сочувствием"} />
			<meta property={"og:image"} content={"https://crispqix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://crispqix.com/img/4800849823.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://crispqix.com/img/4800849823.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://crispqix.com/img/4800849823.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://crispqix.com/img/4800849823.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://crispqix.com/img/4800849823.png"} />
			<meta name={"msapplication-TileImage"} content={"https://crispqix.com/img/4800849823.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				color="--primary"
			>
				Расширение ваших возможностей благодаря комплексным юридическим решениям
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			
Добро пожаловать на страницу услуг Crispqix, где мы рассказываем о широте и глубине нашей юридической поддержки, призванной помочь вам уверенно и четко пройти свой юридический путь. Наши предложения строятся на основе индивидуального подхода, инноваций и расширения возможностей, гарантируя, что каждый клиент получит руководство, необходимое для решения его юридических проблем.

			</Text>
		</Section>
		<Section padding="0px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Наши услуги
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
				margin="0px 0px 30px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Семейное право
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Руководство по жизненным переходам: От бракоразводного процесса до соглашения об опеке над детьми - наш сочувственный подход гарантирует поддержку вам и вашим близким на каждом этапе.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Бизнес-право
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Фундаментальная поддержка вашего предприятия: Независимо от того, начинаете вы или расширяетесь, мы предоставляем основные юридические услуги, включая регистрацию, заключение договоров и консультации по соблюдению нормативных требований, чтобы гарантировать будущее вашего бизнеса.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Интеллектуальная собственность
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Защита ваших инноваций: Разбирайтесь в сложностях интеллектуальной собственности с нашими экспертами на вашей стороне, защищая свои творения и гарантируя, что ваши идеи останутся вашими.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Трудовое право
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Создание справедливых рабочих мест: Мы стремимся создать здоровую, соответствующую законодательству рабочую среду как для работодателей, так и для сотрудников - от составления справочников для сотрудников до консультирования по трудовым спорам.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Закон о недвижимости
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Упрощение сделок с недвижимостью: Наша команда предлагает четкие и ясные рекомендации по прохождению через дебри законодательства о недвижимости, будь то покупка, продажа или управление недвижимостью.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Завещания, трасты и наследство
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Планирование будущего: Обеспечьте спокойствие для себя и своей семьи с помощью наших услуг по составлению завещаний, трастов и планированию наследства, разработанных для защиты вашего наследия и пожеланий.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://crispqix.com/img/7.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
				Создайте свой юридический путь вместе с нами
				</Text>
				<Text font="--base">
				Мы приглашаем вас связаться с нами, чтобы глубже понять, как наши услуги могут быть адаптированы к вашей уникальной ситуации. Будь то консультация в нашем офисе или подробное обсуждение по телефону или электронной почте, мы готовы улучшить ваш юридический опыт и направить вас к успешному решению проблемы.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});